<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft no-print"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid text-dark">
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 class="text-dark "> {{ 'Salary List for month' }} {{ month + '/' + year }}  </h3>
                    </div>
                    <hr>
                    <div class="row" style="overflow-x: auto;">
                        <label class="col-sm-1 mt-1 no-print"> {{ 'Month' }} : </label>
                        <select class="form-control col-sm-1 no-print" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>

                        <label class="col-sm-1 mt-1 no-print"> {{ 'Year' }} : </label>
                        <input type="number" class="form-control col-sm-1 no-print" v-model="year">

                        <button class="btn btn-success mx-3 no-print" style="height: 40px;" v-on:click="search()"> {{ 'Search' }} <i class="fa fa-search"></i> </button>
                        <select class="form-control col-sm-2 float-left no-print" v-model="st_id"> 
                            <option value=""></option>
                            <option v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs)" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                        </select>
                        <span class="col-1 no-print">
                            <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                        </span>
                    </div>
                    <hr class="no-print">
                    <div class="row">
                        <label class="col-sm-1 mt-2"> 1 $ = </label>
                        <input type="number" class="form-control col-sm-1" min="0" v-model="dollar">
                        <button class="col-sm-2 btn btn-primary ml-2 no-print" v-on:click="create_monthly_receipt()"> Create monthly receipt </button>
                        <button class="col-sm-2 btn btn-danger ml-2 no-print" v-on:click="delete_monthly_receipt()"> Delete monthly receipt </button>
                    </div>
                    <hr>
                    <label class="w-100 multi-language my-3"> {{ 'Staff' }} : 
                        <span v-if="st_id != ''"> {{ staffs.filter(obj => obj.st_id == st_id)[0].staff_name }} </span>
                    </label>
                    <div class="alert alert-primary text-center" id="waiting" style="display: none;"> Waiting... </div>
                    <table class="table table-bordered table-striped text-dark text-center mt-2" id="tbl"> 
                        <thead>
                            <th> Name </th>
                            <th> Salary Type </th>
                            <th> Save </th>
                        </thead>
                        <tbody>
                            <tr v-for="employee in pre_gived_salaries" :key="employee.emp_id">
                                <td> {{ employee.full_name }} </td>
                                <td> {{ employee.salary_type }} </td>
                                <td> <v-icon color="success"> mdi-check </v-icon> </td>
                            </tr>
                        </tbody>
                        <!-- <caption class="alert alert-success text-right mt-1 px-5"> Total Salary: {{ get_total_salary.toFixed(3) | money_filter}} $ </caption> -->
                    </table>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import auth from '../../auth.js';
    export default {
        data() {
            return {
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                employees: [],
                staffs: [],
                pre_gived_salaries: [],
                st_id: '',
                dollar: 0,
                showSpecialStaffs: "false"
            }
        },
        beforeCreate() {
            auth("givedSalaryReport");
        },
        created() {
            this.$http.post("/staff/getData").then(({data}) => {
                this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code);
            });
        },
        methods: {
            search() {
                if(this.year >= 2020 && this.st_id != ''){
                    document.getElementById("tbl").style.display = 'none';
                    document.getElementById("waiting").style.display = 'block';
                    this.$http.post('/gived_salary/getPreGivedSalary/' + this.st_id + '/' + this.month + '/' + this.year).then(({data}) => {
                        this.pre_gived_salaries = data;
                        if(typeof this.pre_gived_salaries[0] == 'undefined'){
                            this.dollar = 0;
                        } else {
                            this.dollar = this.pre_gived_salaries[0].dollar_price;
                        }
                        document.getElementById("tbl").style.display = '';
                        document.getElementById("waiting").style.display = 'none';
                    });
                } else {
                    this.pre_gived_salaries = [];
                    document.getElementById("tbl").style.display = 'none';
                }
            },
            create_monthly_receipt() {
                if(Number(this.dollar) <= 0 || Number(this.year < 2020)){
                    this.$alert("Enter correct data", "", "warning");
                } else {
                    this.$confirm("Are you sure you want create receipt for this staff ?", "", "question").then(() => {
                        this.$http.post('/gived_salary/addListOfEmployees/' + this.st_id, {
                            dollar_price: this.dollar,
                            salary_month: this.month,
                            salary_year: this.year
                        }).then(({data}) => {
                            this.pre_gived_salaries = data;
                            this.dollar = this.pre_gived_salaries[0].dollar_price || 0;
                        });
                    });
                }
            },
            delete_monthly_receipt() {
                if(!this.st_id) {
                    this.$alert("Please Chosse a staff", "", "warning");
                    return;
                }
                this.$confirm(`Are you sure you want delete monthly reciept ${this.month}/${this.year} for staff ${this.staffs.find(obj => obj.st_id == this.st_id).staff_name} ?`, '', "question").then(() => {
                    this.$http.delete('/gived_salary/deleteGiveSalary/' + this.month + '/' + this.year + '/' + this.st_id).then(() => {
                        this.pre_gived_salaries = [];
                    }).catch(() => {
                        this.$alert("Something went wrong", "", "error");
                    });
                })
            }
        },
        computed: {
            get_total_salary() {
                var total = 0;
                if(this.pre_gived_salaries.length > 0){
                    this.pre_gived_salaries.forEach(element => {
                        total += element.last_give_salary;
                    });
                }
                return total;
            }
        },
        filters: {
            money_filter(value) {
                if(typeof value == "undefined"){
                    return 0;
                }
                var num = [];
                var point = "";
                value = value.toString();
                if(value.includes('.')){
                    point = value.substring(value.indexOf('.'));
                    num = value.substring(0, value.indexOf('.')).split("").reverse();
                } else {
                    num = value.split("").reverse();
                }
                var arr = [];
                for (let i = 0; i < num.length; i++) {
                    arr.unshift(num[i]);
                    if((i + 1) % 3 == 0){
                        arr.unshift(",");
                    }
                }
                if(point == ".000"){
                    point = "";
                }
                var num_with_comma = arr.join("") + point;
                if(num_with_comma.charAt(0) == ','){
                    return num_with_comma.slice(1);
                }
                return num_with_comma;
            },
        },
        watch: {
            st_id() {
                this.search();
            },
            month() {
                this.search();
            },
            year() {
                this.search();
            }
        }
    }
</script>

<style scoped>
    td {
        padding: .5rem;
        direction: ltr;
    }
    .rtl {
        text-align: right !important;
    }
    @media print {
        .no-print {
            display: none !important;
        }
    }
</style>