import { render, staticRenderFns } from "./monthlySalary.vue?vue&type=template&id=3d0fb8e1&scoped=true&"
import script from "./monthlySalary.vue?vue&type=script&lang=js&"
export * from "./monthlySalary.vue?vue&type=script&lang=js&"
import style0 from "./monthlySalary.vue?vue&type=style&index=0&id=3d0fb8e1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d0fb8e1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCheckbox,VIcon})
